import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUrlInfo } from '../contracts/content/iurl-info';

@Injectable({
  providedIn: 'root'
})
export class PhotocaptureService {

  constructor() { }


  mergePCRtoCrashReport(pcrReport: any, existingCrashReport: any): any {
    let mergedCrashReport = JSON.parse(JSON.stringify(existingCrashReport)); // Deep copy

    mergedCrashReport = this.mapReportDataToCrashReport(pcrReport, mergedCrashReport);
    mergedCrashReport = this.mapVehicles(pcrReport, mergedCrashReport);

    return mergedCrashReport;
  }

  private mapReportDataToCrashReport(pcrReport: any, existingCrashReport: any): void {
    const crashReport = {...existingCrashReport};
    const reportData = pcrReport.reportData;
    if (reportData) {
      crashReport.report_num_2 = reportData.reportId || crashReport.report_num_2;
      crashReport.who_entered = reportData.ownerName || crashReport.who_entered;
    }

    return crashReport;
  }

  private mapVehicle(driver: any, existingVehicle: any): any {
    // Create a shallow copy of the existing vehicle to avoid mutation
    const vehicle = { ...existingVehicle };

    if (driver) {
      // Map license data
      if (driver.license) {
        // vehicle.owner_name = `${driver.license.firstName || ''} ${driver.license.middleName || ''} ${driver.license.lastName || ''}`.trim();
        // vehicle.owner_st = driver.license.address || null;
        vehicle.dr_name = `${driver.license.firstName || ''} ${driver.license.middleName || ''} ${driver.license.lastName || ''}`.trim();
        vehicle.dr_st = driver.license.address || null;
        vehicle.dr_lic_num = driver.license.licenseNumber || null;
        vehicle.dr_lic_state = driver.license.state;
        vehicle.dr_state = driver.license.state;
        vehicle.dr_zip = driver.license.zipCode;
        vehicle.dr_city = driver.license.city;
      }

      // Map insurance data
      if (driver.insurance) {
        vehicle.veh_make = driver.insurance.vehicleMake || vehicle.veh_make;
        vehicle.veh_model = driver.insurance.vehicleModel || vehicle.veh_model;
        vehicle.veh_year = driver.insurance.vehicleYear || vehicle.veh_year;
        vehicle.insco = driver.insurance.insuraceLegalName || driver.insurance.insuraceName || vehicle.insco;
        vehicle.ins_pol_num = driver.insurance.policyNumber || vehicle.ins_pol_num;
      }
    }

    return vehicle;
  }


  private mapVehicles(pcrReport: any, crashReport: any): void {
    const drivers = Object.keys(pcrReport)
      .filter(key => key.startsWith('d'))
      .map(key => pcrReport[key]);

    for (let i = 0; i < drivers.length; i++) {
      if (!crashReport.vehic) {
        crashReport.vehic = [];
      }

      if (!crashReport.vehic[i]) {
        crashReport.vehic[i] = this.newVehicle(i);
      }

      const mappedVehicle = this.mapVehicle(drivers[i], crashReport.vehic[i]);
      crashReport.vehic[i] = mappedVehicle;
    }

    return crashReport;
  }

  private newVehicle(index) {
    return {
      veh_num: index + 1,
      plate_num: null,
      plate_type: null,
      dr_lic_num: null,
      dr_lic_state: null,
      dr_name: null,
      dr_st: null,
      dr_city: null,
      dr_state: null,
      dr_zip: null,
      dr_home_phone: null,
      owner_name: null,
      owner_st: null,
      owner_state: null,
      owner_city: null,
      owner_zip: null,
      ins_agent: null,
      ins_phone: null,
      insco: null,
      ins_pol_num: null,
      veh_year: null,
      veh_make: null,
      veh_model: null,
      owner_info_dr_same: false
    };
  }
}
